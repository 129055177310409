import './App.css';
import Home from './Components/home';
import About from './Components/about';
import Work from './Components/work';
import Contact from './Components/contact';
import Footer from './Components/footer';
import Product from './Components/product';


function App() {
  return (
    <div className="App">
<Home/>
<About/>
<Product/>
<Work/>
<Contact/>
<Footer/>
   
  </div>


 
  );
}

export default App;
